<script setup lang="ts">
import {
    AisInstantSearch,
    AisHits,
    AisStats,
    AisConfigure,
    AisIndex
} from 'vue-instantsearch/vue3/es';
import useAlgolia from "~/composables/useAlgolia";
import LayoutCategorySuggestSearch from "~/components/layout/LayoutCategorySuggestSearch.vue";
import ProductSuggestSearch from "~/components/product/ProductSuggestSearch.vue";

const query = inject('searchQuery');

const algolia = useAlgoliaRef();
const {getProductIndexName, getCategoryIndexName} = useAlgolia();
const {insightsSettings, addSearchHistory} = useAlgolia();

function getRelativeUrl(fullUrl) {
    const url = new URL(fullUrl)
    return url.pathname;
}
</script>
<template>
    <div class="search-suggestions">
        <ais-instant-search :insights="insightsSettings()" :search-client="algolia" :index-name="getProductIndexName()"
                            :future="{preserveSharedStateOnUnmount: true}">
            <ais-configure :hits-per-page.camel="4" :query="query"
                           filters="visibilities.ca7a8b4761a844be94f9cbe265179d6a:30"/>
            <ais-index :index-name="getCategoryIndexName()">
                <ais-configure :hits-per-page.camel="3" :query="query" filters="visible:true AND active:true"/>
                <ais-hits :class-names="{
                    'ais-Hits-list': 'flex flex-col list-none p-0 m-0',
                    'ais-Hits-item': 'w-full p-0 m-0 border-b border-b-solid border-b-brand-gray shadow-none'
                }">
                <template v-slot:item="{ item, sendEvent }">
                    <NuxtLink
                        :key="item.objectID"
                        :to="getRelativeUrl(item.url)"
                        data-test-id="layout-search-suggest-link"
                        class="py-2"
                        @click="[
                          sendEvent('click', item, 'SearchBar: Category Clicked'),
                          query = ''
                        ]"
                    >
                        <LayoutCategorySuggestSearch :category="item"/>
                    </NuxtLink>
                </template>
            </ais-hits>
            </ais-index>
            <ais-hits :class-names="{
                  'ais-Hits-list': 'flex flex-col list-none p-0 m-0',
                  'ais-Hits-item': 'w-full p-0 m-0 border-b border-b-solid border-b-brand-gray shadow-none'
              }">
                <template v-slot:item="{ item, sendEvent }">
                    <NuxtLink
                        :key="item.objectID"
                        :to="getRelativeUrl(item.url)"
                        class="py-2"
                        @click="[
                              sendEvent('click', item, 'SearchBar: Product Clicked'),
                              addSearchHistory(item.objectID, item.__queryID),
                              query = ''
                            ]"
                    >
                        <ProductSuggestSearch :minimal="false" :product="item"/>
                    </NuxtLink>
                </template>
            </ais-hits>
            <ais-stats>
                <template v-slot="{ nbHits }">
                    <div
                        class="h-11 text-sm p-3 text-center transition bg-gray-100"
                        style="clip-path: inset(0% 0% 0% 0%)"
                    >
                        <NuxtLink
                            v-if="nbHits > 0"
                            :to="`/search?query=${query}`"
                        >
                            {{ $t('search.resultCount', nbHits, {count: nbHits}) }}
                        </NuxtLink>
                        <div v-else>
                            <span class="font-sans">{{ $t('search.noResults') }}</span>
                        </div>
                    </div>
                </template>
            </ais-stats>
        </ais-instant-search>
    </div>
</template>
